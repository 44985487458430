import React from 'react';

import { ClassNameProps } from '@ComponentProps';
import { ColorsPalette } from '@UX/themes/types';

interface SeparatorProps extends ClassNameProps {
  lineStyle: 'dashed' | 'solid';
  color?: keyof ColorsPalette;
}

export const Separator: React.FC<SeparatorProps> = ({
  lineStyle,
  color = 'flightCardSeparator',
  className,
}) => (
  <hr
    className={className}
    sx={{
      borderTopWidth: 'outlinedStrokeWeight',
      borderStyle: lineStyle,
      borderColor: color,
      borderBottomWidth: 0,
      marginY: '3xs',
      width: '100%',
    }}
  />
);
